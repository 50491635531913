import React from "react";
import styled from "@emotion/styled";
import { Link as GatsbyLink } from "gatsby";
import { AppBar, Toolbar } from "@material-ui/core";
import { rhythm } from "../utils";

const Link = styled(GatsbyLink)`
  color: #000;
  text-decoration: none;
  margin-right: ${rhythm(1)};
  :hover {
    background-color: hsl(0, 0%, 85%);
  }
`;
export function NavBar() {
  return (
    <AppBar position="static" color="default" variant="outlined">
      <Toolbar variant="regular">
        <Link to="/vehicle-installment-calculator">คำนวณงวดรถ</Link>
        <Link to="/bmi-calculator">คำนวณ BMI</Link>
        <Link to="/risk-to-reward-ratio-calculator">คำนวณ RRR</Link>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
